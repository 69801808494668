.container {
  position: fixed;
  left: 50%;
  top: 15%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);

  max-width: 1600px;
  width: 97%;

  background-color: white;

  z-index: 1000;
}

@media (max-width: 719px) {
  .container {
    width: 100%;

    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    transform: translate(0);
    -webkit-transform: translate(0);
  }
}

.section__title {
  line-height: 1.22em;
  color: #000;
  font-weight: 700;
  margin-top: 0;

  font-size: 23px;
  margin-right: 75px;
  margin-bottom: 25px;
}

@media (max-width: 719px) {
  .section__title span {
    display: block;
  }
}

@media (min-width: 576px) {
  .section__title {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .section__title {
    font-size: 40px;
  }
}

@media (min-width: 992px) {
  .section__title {
    font-size: 45px;
  }
}

@media (min-width: 1200px) {
  .section__title {
    font-size: 55px;
    font-weight: 600;
    line-height: 1.2em;
    margin-bottom: 37px;
  }
}

.section__title span {
  color: #e30014;
}

.region_search__wrapper {
  border: 1px solid #bababa;

  padding: 40px 50px;
}

@media (max-width: 719px) {
  .region_search__wrapper {
    min-height: 100vh;

    border: none;

    padding: 25px;
  }
}

.region_search__close {
  position: absolute;
  top: 57px;
  right: 55px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

@media(max-width: 719px) {
  .region_search__close {
    top: 59px;
    right: 27px;
  }
}

.region_search__close img {
  width: 20px;
  height: 20px;
}

fieldset {
  border-color: #CED4DA;
}
